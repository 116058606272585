import React, { Component } from "react";
import PlayerGroupDataService from "../../../../services/playerGroup.service";
import withStyles from "@mui/styles/withStyles";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AccountDashboardLayout from "src/components/layouts/account-dashboard";
import { useHeaderStore } from "src/store/store";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  SpeedDial,
  SpeedDialIcon,
  SpeedDialAction,
  Box,
  Typography,
} from "@mui/material";

const useStyles = (theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    marginBottom: "5vh",
  },
  chevron: {
    textAlign: "right",
  },
  delete: {
    color: "red",
  },
  playerIcon: {
    verticalAlign: "bottom",
  },
  contentBox: {
    width: "100%",
    maxWidth: 450,
  },
  speedDial: {
    position: "fixed",
    "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
});

class PlayerGroup extends Component {
  constructor(props) {
    super(props);
    this.handleChangeSpeedDial = this.handleChangeSpeedDial.bind(this);

    this.collection_id = this.props.match.params.collection_id;

    this.props.setStateValue("isLoading", true);
    useHeaderStore.setState({
      title: "playergroup.playergroup_other",
      help: "help.playergroup",
    });

    this.state = {
      groups: [],
      privileges: {},
      deletePlayerGroup: false,

      openSpeedDial: false,
    };
  }

  componentDidMount() {
    this.retrievePlayerGroups(this.collection_id);
  }

  retrievePlayerGroups(collection) {
    PlayerGroupDataService.getAll(collection)
      .then((response) => {
        this.setState({
          groups: response.data.groups,
          privileges: response.data.meta.privileges,
        });
        this.props.setStateValue("isLoading", false);
      })
      .catch((e) => {
        if (e.response.status === 403) {
          this.props.history.push("/");
        }
      });
  }

  handleDeletePlayerGroup(group) {
    this.setState({
      deletePlayerGroup: {
        id: group.id,
        name: group.group_name,
      },
    });
  }

  alertDialog() {
    const handleDialogClose = () => {
      this.setState({
        deletePlayerGroup: false,
      });
    };

    const handleDialogConfirm = () => {
      PlayerGroupDataService.delete(
        this.state.deletePlayerGroup.id,
        this.collection_id
      )
        .then((response) => {
          handleDialogClose();
          this.retrievePlayerGroups(this.collection_id);
          this.props.setStateValue("alertMessage", {
            severity: "success",
            message: "Die Spielergruppe wurde erfolgreich gelöscht",
            key: Math.random(),
          });
        })
        .catch((e) => {
          if (e.response.status === 403) {
            this.props.setStateValue("alertMessage", {
              severity: "error",
              message:
                "Du hast keine Berechtigung, um in dieser Sammlung Sammlungsdetails zu löschen",
              errorList: e.response.data.data,
              key: Math.random(),
            });
            this.props.history.push("/");
          }
          this.props.setStateValue("alertMessage", {
            severity: "error",
            message: "Fehler beim Löschen der Spielergruppe",
            errorList: e.response.data.data,
            key: Math.random(),
          });
        });
    };

    return (
      <div>
        <Dialog
          open="open"
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {this.state.deletePlayerGroup.name + " wirklich löschen?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Es wird lediglich die Gruppe ansich gelöscht. Dies bedeutet, dass
              alle Spieler, inklusive der Statistiken, Partien und Leihen, die
              Teil dieser Gruppe sind, bestehen bleiben.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              Abbrechen
            </Button>
            <Button onClick={handleDialogConfirm} color="error" autoFocus>
              Löschen
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  handleChangeSpeedDial() {
    this.setState({
      openSpeedDial: !this.state.openSpeedDial,
    });
  }

  render() {
    const { groups, privileges, deletePlayerGroup, openSpeedDial } = this.state;
    const { classes } = this.props;

    return (
      <React.Fragment>
        {deletePlayerGroup ? this.alertDialog() : null}
        <AccountDashboardLayout>
          <Box className={classes.contentBox}>
            {groups.length > 0 ? (
              <List component="nav">
                {groups.map((group) => (
                  <ListItem>
                    <ListItemText
                      primary={group.group_name}
                      secondary={group.group_description}
                    />
                    {(privileges.owner === 1 ||
                      privileges.collectiondetail_modify === 1) && (
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          size="large"
                          component={Link}
                          to={`/settings/collectionDetails/${this.collection_id}/player-groups/${group.id}/edit`}
                        >
                          <EditIcon />
                        </IconButton>
                        {(privileges.owner === 1 ||
                          privileges.collectiondetail_delete === 1) && (
                          <IconButton
                            edge="end"
                            color="error"
                            size="large"
                            onClick={() => this.handleDeletePlayerGroup(group)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                ))}
              </List>
            ) : (
              <>
                <Typography ml={2}>
                  Diese Sammlung hat noch keine Spielergruppe.
                </Typography>
                <Typography ml={2}>
                  {" "}
                  Klicke{" "}
                  <a
                    href={`/settings/collectionDetails/${this.collection_id}/player-groups/add`}
                  >
                    hier
                  </a>{" "}
                  um eine die erste Gruppe anzulegen.
                </Typography>
              </>
            )}
            {(privileges.owner === 1 ||
              privileges.collectiondetail_insert === 1) && (
              <SpeedDial
                ariaLabel="Spielermenü"
                className={classes.speedDial}
                icon={<SpeedDialIcon icon={<MoreVertIcon />} />}
                open={openSpeedDial}
                onOpen={this.handleChangeSpeedDial}
                onClose={this.handleChangeSpeedDial}
              >
                <SpeedDialAction
                  key="add"
                  icon={<AddIcon color="primary" />}
                  tooltipTitle="Spielergruppe hinzufügen"
                  component={Link}
                  to={`/settings/collectionDetails/${this.collection_id}/player-groups/add`}
                />
              </SpeedDial>
            )}
          </Box>
        </AccountDashboardLayout>
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(PlayerGroup);
