import http from "../http-common";

class ClassBGDBDataService {
  searchGame(type, query, params) {
    return http.get(`/bgdb/search?type=${type}&query=${query}&${params ?? ""}`);
  }

  discover() {
    return http.get(`/bgdb/discover`);
  }
}

const BGDBDataService = new ClassBGDBDataService();
export default BGDBDataService;
