import "./css/App.css";
import React, { Fragment, Suspense } from "react";
import { CssBaseline, Container } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { withRouter, Switch, Route } from "react-router-dom";
import { authenticationService } from "./auth/authentication.service";
import { PrivateRoute2 } from "./components/routes";
import { PrivateRoute } from "./components/PrivateRoute";
import { PublicRoute } from "./components/PublicRoute";
import { socketService } from "./socket/socket.service";
import { useAlertMessageStore, useLoadingStore } from "./store/store";

import CheckoutPage from "./pages/CheckoutPage/CheckoutPage";

//Components
import AlertMessage from "./components/AlertMessage";
import AppHeader from "./components/AppHeader";
import Loading from "./components/Loading";

//Acount
import {
  ConfirmAccount,
  ForgotPassword,
  ResetPassword,
  SignIn,
  SignUp,
} from "./pages/Account";

//Collection
import AddCollection from "./pages/Settings/Collections/AddCollection";
import CollectionDetails from "./pages/Settings/Collections/Collection/CollectionDetails";

//Discover
import { DicoverPage, DiscoverGamePage } from "./pages/Discover";

//Event
import Events from "./pages/Event/Events";
import AddEvent from "./pages/Event/AddEvent";
import EditEvent from "./pages/Event/EditEvent";
import EventDetails from "./pages/Event/EventDetails";

//Game
import AddGame from "./pages/Game/AddGame";
import GameDetails from "./pages/Game/GameDetails";
import EditGame from "./pages/Game/EditGame";

//Home
import Home from "./pages/Home/Home";

//Match
import AddMatch from "./pages/Match/AddMatch";
import EditMatch from "./pages/Match/EditMatch";
import MatchHistory from "./pages/Match/MatchHistory";
import { PlayDetails } from "./pages/Match";

//Lend
import AddLend from "./pages/Lend/AddLend";
import EditLend from "./pages/Lend/EditLend";
import LendHistory from "./pages/Lend/LendHistory";

//Location
import Locations from "./pages/Settings/Collections/Collection/Location/Locations";

//Other
import LegalNotice from "./pages/Settings/Miscellaneous/LegalNotice";
import PageNotFound from "./pages/Other/PageNotFound";
import Privacy from "./pages/Other/Privacy";

//Permission
import AddUser from "./pages/Permission/AddUser";
import EditUser from "./pages/Permission/EditUser";
import Permissions from "./pages/Permission/Permissions";

//Player
import AddPlayer from "./pages/Player/AddPlayer";
import EditPlayer from "./pages/Player/EditPlayer";
import Players from "./pages/Player/Players";

//PlayerGroup
import AddPlayerGroup from "./pages/Settings/Collections/PlayGroups/AddPlayGroup";
import PlayerGroups from "./pages/Settings/Collections/PlayGroups/PlayGroups";
import EditPlayGroup from "./pages/Settings/Collections/PlayGroups/EditPlayGroup";

//Search
import Search from "./pages/Search/Search";

//Session
import Sessions from "./pages/Settings/Sessions/Sessions";

//Settings
import Settings from "./pages/Settings/Settings";

//Settings -> Account
import ChangePassword from "./pages/Settings/Account/ChangePassword";
import EditProfile from "./pages/Settings/Account/EditProfile";
import Membership from "./pages/Settings/Account/Membership";
import UpgradeAccount from "./pages/Settings/Account/UpgradeAccount";
import MembershipHistory from "./pages/Settings/Account/MembershipHistory";

//Settings -> Collections
import Collections from "./pages/Settings/Collections/Collections";

//Settings -> Notification
import NotificationSettings from "./pages/Settings/Notifications/NotificationSettings";

//Settings -> Miscellaneous
import SupportUs from "./pages/Settings/Miscellaneous/SupportUs";
import ContactForm from "./pages/Settings/Miscellaneous/ContactForm";

//Statistic
import CollectionStatistic from "src/pages/Statistic/CollectionStatistic";
import PlayerStatistic from "src/pages/Statistic/PlayerStatistic";

//VersionHistory
import VersionHistory from "src/pages/Other/VersionHistory";

//Public
//import PublicHome from "./pages/Public/PublicHome";
import PublicGameDetails from "./pages/Public/PublicGameDetails";
import AccountStatistic from "./pages/Statistic/AccountStatistic";

const useStyles = (theme) => ({
  container: {
    [theme.breakpoints.up("md")]: {
      marginLeft: "240px",
      maxWidth: "calc(99.9% - 240px)",
    },
    [theme.breakpoints.up("xxxl")]: {
      marginLeft: "auto",
      maxWidth: "1920px",
    },
  },
  main: {
    marginTop: "64px",
    padding: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    /*[theme.breakpoints.between('md','xxl')]: {
        paddingLeft: '240px'
      },*/
  },
});

class MainWrapper extends React.Component {
  constructor() {
    super();
    this.state = {
      pageTitle: "",
      alertMessage: false,
      alertMessageStore: useAlertMessageStore.getState(),
      isLoading: false,
      help: false,

      socket: null,
      currentUser: null,
      forceCollectionUpdate: false,
    };
    this.setStateValue = this.setStateValue.bind(this);
    this.resetAllStates = this.resetAllStates.bind(this);
  }

  componentDidMount() {
    authenticationService.currentUser.subscribe((x) =>
      this.setState({ currentUser: x })
    );
    this.alertMessage = useAlertMessageStore.subscribe(
      (alertMessageStore) => {
        this.setState({ alertMessageStore });
      },
      (state) => state
    );

    this.newIsLoading = useLoadingStore.subscribe(
      (loadingStore) => {
        this.setState(loadingStore);
      },
      (state) => state
    );

    this.props.history.listen(() => {
      if (window.swUpdateReady) {
        window.swUpdateReady = false;
        window.stop();
        window.location.reload();
      }
    });
    const socket = socketService.connect();
    this.setState({ socket: socket });
  }

  setStateValue(key, value) {
    var state = {};
    state[key] = value;
    this.setState(state);
  }

  resetAllStates() {
    this.setState({
      alertMessage: false,
    });
  }

  render() {
    const { currentUser, alertMessage, isLoading, alertMessageStore } =
      this.state;

    const { classes } = this.props;
    return (
      <Fragment>
        <Suspense fallback={<Loading />}>
          <CssBaseline />
          {(alertMessage || alertMessageStore.alertMessage) && (
            <AlertMessage
              message={
                alertMessage.message || alertMessageStore.alertMessage.message
              }
              severity={
                alertMessage.severity || alertMessageStore.alertMessage.severity
              }
              key={alertMessage.key}
              errorList={
                alertMessage.errorList ||
                alertMessageStore.alertMessage.errorList
              }
              resetAllStates={this.resetAllStates}
            />
          )}
          {isLoading && <Loading />}
          {currentUser && (
            <>
              <PrivateRoute
                component={AppHeader}
                pageTitle={this.state.pageTitle}
                notificationCount={this.state.notificationCount}
                socket={this.state.socket}
                forceCollectionUpdate={this.state.forceCollectionUpdate}
                setStateValue={this.setStateValue}
                help={this.state.help}
              />
              <Container className={classes.container}>
                <main className={classes.main}>
                  <Switch>
                    <PrivateRoute
                      exact
                      path="/"
                      component={Home}
                      setStateValue={this.setStateValue}
                    />
                    <PrivateRoute exact path="/add-game" component={AddGame} />
                    <PrivateRoute
                      exact
                      path="/discover"
                      component={DicoverPage}
                    />
                    <PrivateRoute
                      exact
                      path="/discover/:id"
                      component={DiscoverGamePage}
                    />
                    <PrivateRoute
                      exact
                      path="/games/:game_id"
                      component={GameDetails}
                      setStateValue={this.setStateValue}
                    />
                    <PrivateRoute
                      exact
                      path="/games/:game_id/edit"
                      component={EditGame}
                      setStateValue={this.setStateValue}
                    />
                    <PrivateRoute
                      exact
                      path="/games/:game_id/add-match/"
                      component={AddMatch}
                      setStateValue={this.setStateValue}
                    />
                    <PrivateRoute
                      exact
                      path="/games/:game_id/add-lend/"
                      component={AddLend}
                      setStateValue={this.setStateValue}
                    />
                    <PrivateRoute
                      exact
                      path="/plays/:playID"
                      component={PlayDetails}
                    />
                    <PrivateRoute
                      exact
                      path="/edit-match/:match_id"
                      component={EditMatch}
                      setStateValue={this.setStateValue}
                    />
                    <PrivateRoute
                      exact
                      path="/edit-lend/:lend_id"
                      component={EditLend}
                      setStateValue={this.setStateValue}
                    />
                    <PrivateRoute
                      exact
                      path="/settings"
                      currentUser={currentUser}
                      component={Settings}
                      pageTitle="setting.setting_other"
                      alwaysOpen={true}
                      help="help.settings"
                      setStateValue={this.setStateValue}
                    />
                    <PrivateRoute
                      exact
                      path="/settings/account/edit-profile"
                      currentUser={currentUser}
                      component={EditProfile}
                      setStateValue={this.setStateValue}
                    />
                    <PrivateRoute
                      exact
                      path="/settings/account/change-password"
                      currentUser={currentUser}
                      component={ChangePassword}
                      setStateValue={this.setStateValue}
                    />
                    <PrivateRoute
                      exact
                      path="/settings/account/membership"
                      currentUser={currentUser}
                      component={Membership}
                      setStateValue={this.setStateValue}
                    />
                    <PrivateRoute
                      exact
                      path="/settings/account/membership/upgrade"
                      currentUser={currentUser}
                      component={UpgradeAccount}
                      setStateValue={this.setStateValue}
                    />
                    <PrivateRoute
                      exact
                      path="/settings/account/membership/history"
                      currentUser={currentUser}
                      component={MembershipHistory}
                      setStateValue={this.setStateValue}
                    />
                    <PrivateRoute
                      exact
                      path="/settings/notifications"
                      currentUser={currentUser}
                      component={NotificationSettings}
                      setStateValue={this.setStateValue}
                    />
                    <PrivateRoute
                      exact
                      path="/settings/sessions"
                      currentUser={currentUser}
                      component={Sessions}
                      setStateValue={this.setStateValue}
                    />
                    <PrivateRoute
                      exact
                      path="/settings/collections"
                      component={Collections}
                      setStateValue={this.setStateValue}
                    />
                    <PrivateRoute
                      exact
                      path="/settings/collections/add-collection"
                      component={AddCollection}
                      setStateValue={this.setStateValue}
                    />
                    <PrivateRoute
                      exact
                      path="/settings/collections/:collection_id"
                      component={CollectionDetails}
                      alwaysOpen={true}
                      setStateValue={this.setStateValue}
                    />
                    <PrivateRoute
                      exact
                      path="/settings/collections/:collectionID/locations"
                      component={Locations}
                    />
                    <PrivateRoute
                      exact
                      path="/settings/collections/:collection_id/players"
                      component={Players}
                      setStateValue={this.setStateValue}
                    />
                    <PrivateRoute
                      exact
                      path="/settings/collections/:collection_id/players/add"
                      component={AddPlayer}
                      setStateValue={this.setStateValue}
                    />
                    <PrivateRoute
                      exact
                      path="/settings/collections/:collection_id/players/:player_id/"
                      component={EditPlayer}
                      setStateValue={this.setStateValue}
                    />
                    <PrivateRoute
                      exact
                      path="/settings/collections/:collection_id/player-groups/"
                      component={PlayerGroups}
                      setStateValue={this.setStateValue}
                    />
                    <PrivateRoute
                      exact
                      path="/settings/collectionDetails/:collection_id/player-groups/add"
                      component={AddPlayerGroup}
                      setStateValue={this.setStateValue}
                    />
                    <PrivateRoute
                      exact
                      path="/settings/collectionDetails/:collection_id/player-groups/:group_id/edit"
                      component={EditPlayGroup}
                      setStateValue={this.setStateValue}
                    />
                    <PrivateRoute
                      exact
                      path="/settings/collections/:collection_id/permissions"
                      component={Permissions}
                      setStateValue={this.setStateValue}
                    />
                    <PrivateRoute
                      exact
                      path="/settings/collections/:collection_id/permissions/add"
                      component={AddUser}
                      setStateValue={this.setStateValue}
                    />
                    <PrivateRoute
                      exact
                      path="/settings/collections/:collection_id/permissions/:permission_id/edit"
                      component={EditUser}
                      setStateValue={this.setStateValue}
                    />
                    <PrivateRoute
                      exact
                      path="/matchHistory"
                      component={MatchHistory}
                      collectionID={this.state.collectionID}
                      setStateValue={this.setStateValue}
                    />
                    <PrivateRoute
                      exact
                      path="/lendHistory"
                      component={LendHistory}
                      collectionID={this.state.collectionID}
                      setStateValue={this.setStateValue}
                    />
                    <PrivateRoute
                      exact
                      path="/search"
                      component={Search}
                      setStateValue={this.setStateValue}
                    />
                    <PrivateRoute
                      exact
                      path="/statistic/account"
                      component={AccountStatistic}
                    />
                    <PrivateRoute
                      exact
                      path="/statistic/collection/:collection_id"
                      component={CollectionStatistic}
                      setStateValue={this.setStateValue}
                    />
                    <PrivateRoute
                      exact
                      path="/statistic/collection/:collection_id/player/:player_id"
                      component={PlayerStatistic}
                      setStateValue={this.setStateValue}
                    />
                    {/*    <PrivateRoute exact path='/events' component={Events} setStateValue={this.setStateValue} />
                  <PrivateRoute exact path='/event/:id' component={EventDetails} setStateValue={this.setStateValue} />
                  <PrivateRoute exact path='/events/addEvent' component={AddEvent} setStateValue={this.setStateValue} />
          <PrivateRoute exact path='/events/editEvent/:id' component={EditEvent} setStateValue={this.setStateValue} /> */}
                    <PrivateRoute
                      exact
                      path="/privacy"
                      component={Privacy}
                      setStateValue={this.setStateValue}
                    />
                    <PrivateRoute
                      exact
                      path="/legalNotice"
                      component={LegalNotice}
                      setStateValue={this.setStateValue}
                    />
                    <PrivateRoute
                      exact
                      path="/support"
                      component={SupportUs}
                      setStateValue={this.setStateValue}
                    />
                    <PrivateRoute
                      exact
                      path="/contact"
                      currentUser={currentUser}
                      component={ContactForm}
                      setStateValue={this.setStateValue}
                    />
                    <PrivateRoute
                      exact
                      path="/versions"
                      component={VersionHistory}
                    />
                    <PrivateRoute
                      exact
                      path="/resetpassword"
                      component={ResetPassword}
                      setStateValue={this.setStateValue}
                    />
                    <Route
                      path="*"
                      exact
                      render={(props) => (
                        <PageNotFound
                          {...props}
                          setStateValue={this.setStateValue}
                        />
                      )}
                    />
                  </Switch>
                </main>
              </Container>
            </>
          )}
          {!currentUser && (
            <Switch>
              {/*<Route
                path="/public/collections/:collection_id"
                exact
                render={(props) => (
                  <PublicHome {...props} setStateValue={this.setStateValue} />
                )}
              />*/}
              <Route
                path="/public/games/:game_id"
                exact
                render={(props) => (
                  <PublicGameDetails
                    {...props}
                    setStateValue={this.setStateValue}
                  />
                )}
              />
              <Route
                path="/confirmaccount"
                exact
                render={(props) => <ConfirmAccount />}
              />
              <Route
                path="/forgotpassword"
                exact
                render={(props) => <ForgotPassword />}
              />
              <Route
                path="/resetpassword"
                exact
                render={(props) => <ResetPassword />}
              />
              <Route path="/signup" exact render={(props) => <SignUp />} />
              <Route
                path="/events/:id"
                exact
                render={(props) => (
                  <EventDetails {...props} setStateValue={this.setStateValue} />
                )}
              />
              <Route
                path="/legalNotice"
                exact
                render={(props) => (
                  <PublicRoute>
                    <LegalNotice
                      {...props}
                      setStateValue={this.setStateValue}
                    />
                  </PublicRoute>
                )}
              />
              <Route
                path="/privacy"
                exact
                render={(props) => (
                  <PublicRoute>
                    <Privacy {...props} setStateValue={this.setStateValue} />
                  </PublicRoute>
                )}
              />
              <Route path="*" exact render={(props) => <SignIn />} />
            </Switch>
          )}
        </Suspense>
      </Fragment>
    );
  }
}

export default withRouter(withStyles(useStyles)(MainWrapper));
