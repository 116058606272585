import React, { Component } from "react";
import { Link } from "react-router-dom";
import withStyles from "@mui/styles/withStyles";
import GameDataService from "../../services/game.service";
import MatchDataService from "../../services/match.service";
import LendDataService from "../../services/lend.service";
import moment from "moment";
import SwipeableViews from "react-swipeable-views";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import noImage from "../../images/no_image.png";
import DeleteIcon from "@mui/icons-material/Delete";
import CasinoIcon from "@mui/icons-material/Casino";
import InfoIcon from "@mui/icons-material/Info";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { useGameSearchStore, useHeaderStore } from "../../store/store";

import {
  Avatar,
  Chip,
  Container,
  Typography,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Tabs,
  Tab,
  Box,
  SpeedDial,
  SpeedDialIcon,
  SpeedDialAction,
  MobileStepper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  FormControlLabel,
  Rating,
  Switch,
} from "@mui/material";

const useStyles = (theme) => ({
  container: {
    textAlign: "center",
  },
  picture: {
    height: "15vh",
  },
  avatar: {
    height: "15vh",
  },
  speedDial: {
    position: "fixed",
    "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
  speedDialMatch: {
    position: "inherit",
    maxWidth: 0,
    maxHeight: 0,
    marginLeft: "auto",
    marginRight: "20px",
    "& .MuiFab-primary": {
      color: "#333",
      boxShadow: "none",
      background: "transparent",
    },
  },
  tableAltRow: {
    "&:nth-child(odd)": {
      backgroundColor: "#f2f2f2",
    },
  },
  tableAltTable: {
    "& tr:nth-child(even)": {
      backgroundColor: "#f2f2f2",
    },
  },
  chipRoot: {
    height: "auto",
    minHeight: "32px",
    margin: "1px",
  },
  chipLabel: {
    whiteSpace: "normal",
    margin: "1px",
    height: "auto",
    minHeight: "32px",
    display: "flex",
    alignItems: "center",
  },
  metainfo: {
    margin: theme.spacing(2),
  },
  SwipeAbleContainer: {
    paddingBottom: theme.spacing(5),
    minHeight: "55vh",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  winnerIcon: {
    color: "gold !important",
    verticalAlign: "middle",
  },
  statisticHeader: {
    marginTop: "1em",
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pt={1}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

class GameDetails extends Component {
  constructor(props) {
    super(props);
    this.handleCategoryClick = this.handleCategoryClick.bind(this);
    this.handleChangeSpeedDial = this.handleChangeSpeedDial.bind(this);
    this.handleChangeSpeedDialMatch =
      this.handleChangeSpeedDialMatch.bind(this);
    this.handleDeleteMatch = this.handleDeleteMatch.bind(this);
    this.handleDeleteLend = this.handleDeleteLend.bind(this);
    this.handleChangeLeaderboard = this.handleChangeLeaderboard.bind(this);

    this.collection_id = localStorage.getItem("collectionID");

    this.props.setStateValue("isLoading", true);
    useHeaderStore.setState({
      title: "game.gameDetail_other",
      help: "help.gameDetails",
    });

    this.state = {
      id: "",
      name: "",
      ean: "",
      designer: "",
      publisher: "",
      minPlayers: "",
      maxPlayers: "",
      minAge: "",
      playtime: "",
      datePurchased: "",
      yearpublished: "",
      coop: false,
      recordPoints: false,
      description: "",
      categories: [],
      maingame: [],
      matches: [],
      lends: [],
      extensions: [],
      locationID: "",
      rating: "",
      image: "",
      apiUrl: "",
      createdBy: "",

      deleteMatch: false,
      deleteMatchID: "",
      deleteMatchDate: "",
      deletedMatch: false,
      showLeaderboardWithExtensions: true,

      openSpeedDial: false,
      openSpeedDialMatch: null,
      privileges: "",

      tabIndex: 0,
    };
  }

  componentDidMount() {
    const id = this.props.match.params.game_id;
    this.retrieveGameDetails(id);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.location.key !== this.props.location.key) {
      const id = this.props.match.params.game_id;
      this.retrieveGameDetails(id);
    }
  }

  retrieveGameDetails(id) {
    GameDataService.get(id, this.collection_id)
      .then((response) => {
        this.setState({
          id: response.data.data.game.id,
          collection_id: response.data.data.game.collection_id,
          collection_name: response.data.data.game.collection_name,
          name: response.data.data.game.name,
          ean: response.data.data.game.ean,
          designer: response.data.data.game.designer,
          publisher: response.data.data.game.publisher,
          minPlayers: response.data.data.game.min_players,
          maxPlayers: response.data.data.game.max_players,
          minAge: response.data.data.game.min_age,
          playtime: response.data.data.game.playtime,
          datePurchased: moment(
            response.data.data.game.date_purchased
          ).isValid()
            ? moment(response.data.data.game.date_purchased).format(
                "DD.MM.YYYY"
              )
            : "",
          yearpublished: response.data.data.game.yearpublished,
          coop: response.data.data.game.coop,
          recordPoints: response.data.data.game.recordPoints,
          maingame: response.data.data.maingame,
          rating: response.data.data.game.rating,
          description: response.data.data.game.description,
          categories: response.data.data.categories,
          image: response.data.data.game.image,
          bgdbID: response.data.data.game.bgdb_id,
          current_lend: response.data.data.game.current_lend,
          createdAt: moment(response.data.data.game.created_at).isValid()
            ? moment(response.data.data.game.created_at).format("DD.MM.YYYY")
            : "",
          createdBy: response.data.data.game.created_by_username,
          updatedAt: moment(response.data.data.game.updated_at).isValid()
            ? moment(response.data.data.game.updated_at).format("DD.MM.YYYY")
            : "",
          updatedBy: response.data.data.game.updated_by_username,

          location: response.data.data.location,
          extensions: response.data.data.extensions,
          matches: response.data.data.matches,
          lends: response.data.data.lends,
          statistic: response.data.data.statistic,
          leaderboard: response.data.data.leaderboard,

          privileges: response.data.meta.privileges,
        });
        this.props.setStateValue("isLoading", false);
      })
      .catch((e) => {
        if (e.response.status === 403) {
          this.props.setStateValue("alertMessage", {
            severity: "error",
            message: `error.${e.response.data.error.code}`,
            key: Math.random(),
          });
          this.props.history.push(`/`);
        }
      });
  }

  handleCategoryClick(categoryID) {
    useGameSearchStore.setState({ categoryFilter: categoryID });
    this.props.history.push(`/`);
  }

  a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  handleTabChange = (event, newValue) => {
    this.setState({
      tabIndex: newValue,
    });
  };

  handleIndexChange = (index) => {
    this.setState({
      tabIndex: index,
    });
  };

  handleChangeSpeedDial() {
    this.setState({
      openSpeedDial: !this.state.openSpeedDial,
    });
  }

  handleChangeSpeedDialMatch(id) {
    this.setState({
      openSpeedDialMatch: id,
    });
  }

  handleChangeLeaderboard(event) {
    this.setState({
      showLeaderboardWithExtensions: event.target.checked,
    });
  }

  handleDeleteMatch(id, date) {
    this.setState({
      deleteMatch: true,
      deleteMatchID: id,
      deleteMatchDate: date,
    });
  }

  handleDeleteLend(id) {
    this.setState({
      deleteLend: true,
      deleteLendID: id,
    });
  }

  alertDialogMatch() {
    const handleDialogClose = () => {
      this.setState({
        deleteMatch: false,
      });
    };

    const handleDialogConfirm = (match_id) => {
      MatchDataService.delete(match_id)
        .then((response) => {
          handleDialogClose();
          this.props.setStateValue("alertMessage", {
            severity: "success",
            message: "Die Partie wurde erfolgreich gelöscht",
            key: Math.random(),
          });
          this.retrieveGameDetails(this.state.id);
        })
        .catch((e) => {
          this.props.setStateValue("alertMessage", {
            severity: "error",
            message: "Fehler beim Löschen der Partie",
            key: Math.random(),
          });
          console.log(e);
        });
    };

    return (
      <div>
        <Dialog
          open="open"
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Partie wirklich löschen?
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Soll die Partie vom{" "}
              {moment(this.state.deleteMatchDate).format("DD.MM.YYYY")} wirklich
              gelöscht werden?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              Abbrechen
            </Button>
            <Button
              onClick={() => handleDialogConfirm(this.state.deleteMatchID)}
              color="error"
              autoFocus
            >
              Löschen
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  alertDialogLend() {
    const handleDialogClose = () => {
      this.setState({
        deleteLend: false,
      });
    };

    const handleDialogConfirm = (lend_id) => {
      LendDataService.delete(lend_id)
        .then((response) => {
          handleDialogClose();
          this.props.setStateValue("alertMessage", {
            severity: "success",
            message: "Die Leihe wurde erfolgreich gelöscht",
            key: Math.random(),
          });
          this.retrieveGameDetails(this.state.id);
        })
        .catch((e) => {
          if (e.response.status === 403) {
            this.props.setStateValue("alertMessage", {
              severity: "error",
              message: `error.${e.response.data.error.code}`,
              key: Math.random(),
            });
            this.props.history.push(`/`);
          } else {
            this.props.setStateValue("alertMessage", {
              severity: "error",
              message: "Fehler beim Löschen der Leihe",
              key: Math.random(),
            });
            console.log(e);
          }
        });
    };

    return (
      <div>
        <Dialog
          open="open"
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Leihe wirklich löschen?
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Soll die Leihe wirklich gelöscht werden?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              Abbrechen
            </Button>
            <Button
              onClick={() => handleDialogConfirm(this.state.deleteLendID)}
              color="error"
              autoFocus
            >
              Löschen
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  render() {
    const game = this.state;
    const {
      privileges,
      openSpeedDial,
      openSpeedDialMatch,
      deleteMatch,
      deleteLend,
    } = this.state;
    const { classes } = this.props;
    const editable = game.collection_id == this.collection_id;

    return (
      <React.Fragment>
        {deleteMatch && this.alertDialogMatch()}
        {deleteLend && this.alertDialogLend()}
        <Typography variant="h4" align="center" gutterBottom>
          {game.name}
        </Typography>
        {game.maingame.name && (
          <Typography variant="h6" align="center" gutterBottom>
            Erweiterung von <b>{game.maingame.name}</b>
          </Typography>
        )}
        {game.collection_id != this.collection_id && (
          <Typography
            variant="caption"
            align="center"
            gutterBottom
            sx={{ margin: "auto", marginTop: "-1em", display: "table" }}
          >
            Spielesammlung: {game.collection_name}
          </Typography>
        )}
        <Container className={classes.container}>
          <img
            src={game.image || noImage}
            alt={game.name}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = noImage;
            }}
            className={classes.picture}
          />
        </Container>
        <Box>
          <Tabs
            value={this.state.tabIndex}
            onChange={this.handleTabChange}
            aria-label="gameDetails tabs"
            indicatorColor="primary"
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
          >
            <Tab label="Details" {...this.a11yProps(0)} />
            <Tab label="Partien" {...this.a11yProps(1)} />
            <Tab label="Leihen" {...this.a11yProps(2)} />
            <Tab label="Statistik" {...this.a11yProps(3)} />
            <Tab label="Leaderboard" {...this.a11yProps(4)} />
          </Tabs>
          <MobileStepper
            variant="dots"
            steps={5}
            activeStep={this.state.tabIndex}
            position="static"
            sx={{ justifyContent: "center", display: { md: "none" } }}
          />
        </Box>
        <SwipeableViews
          axis="x"
          index={this.state.tabIndex}
          onChangeIndex={this.handleIndexChange}
          className={classes.SwipeAbleContainer}
        >
          <TabPanel value={this.state.tabIndex} index={0} dir="ltr">
            <TableContainer component={Paper}>
              <Table
                className={classes.tableAltTable}
                aria-label="game-details"
                sx={{ tableLayout: "fixed" }}
              >
                <TableBody>
                  <TableRow>
                    <TableCell>EAN</TableCell>
                    <TableCell>{game.ean}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Hauptspiel</TableCell>
                    <TableCell>
                      {game.maingame.id && (
                        <Chip
                          avatar={
                            <Avatar
                              alt={game.maingame.name}
                              src={
                                game.maingame.image
                                  ? game.maingame.image
                                  : Array.from(game.maingame.name)[0]
                              }
                            />
                          }
                          component={Link}
                          to={`/games/${game.maingame.id}`}
                          label={game.maingame.name}
                          classes={{
                            root: classes.chipRoot,
                            label: classes.chipLabel,
                          }}
                          clickable
                        />
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Erweiterungen</TableCell>
                    <TableCell>
                      {game.extensions &&
                        game.extensions.map((extension, index) => (
                          <Chip
                            avatar={
                              <Avatar
                                alt={extension.name}
                                src={
                                  extension.image
                                    ? extension.image
                                    : Array.from(extension.name)[0]
                                }
                              />
                            }
                            component={Link}
                            to={`/games/${extension.id}`}
                            label={extension.name}
                            classes={{
                              root: classes.chipRoot,
                              label: classes.chipLabel,
                            }}
                            clickable
                          />
                        ))}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Autor</TableCell>
                    <TableCell>{game.designer}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Publisher</TableCell>
                    <TableCell>{game.publisher}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Min. Spieler</TableCell>
                    <TableCell>{game.minPlayers}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Max. Spieler</TableCell>
                    <TableCell>{game.maxPlayers}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Alter</TableCell>
                    <TableCell>
                      {game.minAge ? game.minAge + "+" : ""}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Spieldauer</TableCell>
                    <TableCell>{game.playtime}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Anschaffungsdatum</TableCell>
                    <TableCell>{game.datePurchased}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Veröffentlicht</TableCell>
                    <TableCell>{game.yearpublished}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Koop</TableCell>
                    <TableCell>{game.coop ? "Ja" : "Nein"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Punkte erfassen</TableCell>
                    <TableCell>{game.recordPoints ? "Ja" : "Nein"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Genre</TableCell>
                    <TableCell>
                      {game.categories &&
                        game.categories.map((category, index) => (
                          <Chip
                            onClick={() =>
                              this.handleCategoryClick(category.id)
                            }
                            label={category.name}
                            classes={{
                              root: classes.chipRoot,
                              label: classes.chipLabel,
                            }}
                            clickable
                          />
                        ))}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Standort</TableCell>
                    <TableCell>{game.location?.name}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Hinweis</TableCell>
                    <TableCell>{game.description}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Bewertung</TableCell>
                    <TableCell>
                      <Rating
                        value={game.rating}
                        precision={0.5}
                        readOnly
                        icon={<CasinoIcon color={"primary"} />}
                        emptyIcon={<CasinoIcon />}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>BGG-ID</TableCell>
                    <TableCell>
                      <Link
                        to={{
                          pathname: `https://boardgamegeek.com/boardgame/${game.bgdbID}`,
                        }}
                        target="_blank"
                      >
                        {game.bgdbID}
                      </Link>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Typography
              className={classes.metainfo}
              variant="body2"
              align="left"
              gutterBottom
            >
              Hinzugefügt: <em>{game.createdAt}</em> von{" "}
              <em>{game.createdBy}</em>
              <br />
              Zuletzt bearbeitet: <em>{game.updatedAt}</em> von{" "}
              <em>{game.updatedBy}</em>
            </Typography>
          </TabPanel>
          <TabPanel value={this.state.tabIndex} index={1}>
            {game.matches.length > 0 ? (
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="match-history">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ width: "50px" }}>Datum</TableCell>
                      <TableCell>Spieler</TableCell>
                      <TableCell>Erweiterungen</TableCell>
                      <TableCell>Anmerkung</TableCell>
                      {editable &&
                        (privileges.owner === 1 ||
                          privileges.match_modify === 1) && (
                          <TableCell></TableCell>
                        )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {game.matches.map((match, index) => (
                      <TableRow className={classes.tableAltRow}>
                        <TableCell>
                          {moment(match.date).format("DD.MM.YY")}
                        </TableCell>
                        <TableCell>
                          {match.players?.map((player, index) => (
                            <Chip
                              icon={
                                player.winner === 1 && (
                                  <EmojiEventsIcon
                                    className={classes.winnerIcon}
                                  />
                                )
                              }
                              label={player.player_name}
                              classes={{
                                root: classes.chipRoot,
                                label: classes.chipLabel,
                              }}
                            />
                          ))}
                        </TableCell>
                        <TableCell>
                          {match.extensions?.map((extension, index) => (
                            <Chip
                              avatar={
                                <Avatar
                                  alt={extension.name}
                                  src={
                                    extension.image
                                      ? extension.image
                                      : Array.from(extension.name)[0]
                                  }
                                />
                              }
                              component={Link}
                              to={`/games/${extension.id}`}
                              label={extension.name}
                              clickable
                              classes={{
                                root: classes.chipRoot,
                                label: classes.chipLabel,
                              }}
                            />
                          ))}
                        </TableCell>
                        <TableCell>{match.comment}</TableCell>
                        {editable &&
                          (privileges.owner === 1 ||
                            privileges.match_modify === 1) && (
                            <TableCell style={{ maxWidth: "50px" }}>
                              <SpeedDial
                                ariaLabel="Matchmenu"
                                color="secondary"
                                className={classes.speedDialMatch}
                                icon={<SpeedDialIcon icon={<MoreVertIcon />} />}
                                direction="left"
                                key={match.id}
                                open={openSpeedDialMatch === match.id}
                                onOpen={() =>
                                  this.handleChangeSpeedDialMatch(match.id)
                                }
                                onClose={() =>
                                  this.handleChangeSpeedDialMatch(null)
                                }
                              >
                                <SpeedDialAction
                                  key="Details"
                                  icon={<InfoIcon />}
                                  tooltipTitle="Details anzeigen"
                                  component={Link}
                                  to={`/plays/${match.id}`}
                                />
                                <SpeedDialAction
                                  key="Bearbeiten"
                                  icon={<EditIcon />}
                                  tooltipTitle="Partie bearbeiten"
                                  component={Link}
                                  to={`/edit-match/${match.id}`}
                                />
                                {editable &&
                                  (privileges.owner === 1 ||
                                    privileges.match_delete === 1) && (
                                    <SpeedDialAction
                                      key="Löschen"
                                      icon={<DeleteIcon color="error" />}
                                      tooltipTitle="Partie löschen"
                                      onClick={() =>
                                        this.handleDeleteMatch(
                                          match.id,
                                          match.date
                                        )
                                      }
                                    />
                                  )}
                              </SpeedDial>
                            </TableCell>
                          )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : privileges.match_view === 1 || privileges.owner === 1 ? (
              <Typography gutterBottom>
                Für dieses Spiel wurde noch keine Partie erfasst.
              </Typography>
            ) : (
              <Typography gutterBottom>
                Du hast keine Berechtigung, um die Partien dieser Sammlung zu
                sehen.
              </Typography>
            )}
          </TabPanel>
          <TabPanel value={this.state.tabIndex} index={2}>
            {game.lends.length > 0 ? (
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="lend-history">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Verliehen</TableCell>
                      <TableCell>Zurückgegeben</TableCell>
                      <TableCell>Anmerkung</TableCell>
                      {editable &&
                        (privileges.owner === 1 ||
                          privileges.lend_modify === 1) && (
                          <TableCell></TableCell>
                        )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {game.lends.map((lend, index) => (
                      <TableRow className={classes.tableAltRow}>
                        <TableCell>{lend.player_name}</TableCell>
                        <TableCell>
                          {moment(lend.date_start).format("DD.MM.YYYY")}
                        </TableCell>
                        <TableCell>
                          {lend.date_end
                            ? moment(lend.date_end).format("DD.MM.YYYY")
                            : ""}
                        </TableCell>
                        <TableCell>{lend.comment}</TableCell>
                        {editable &&
                          (privileges.owner === 1 ||
                            privileges.lend_modify === 1) && (
                            <TableCell style={{ maxWidth: "50px" }}>
                              <SpeedDial
                                ariaLabel="Lendmenu"
                                color="secondary"
                                className={classes.speedDialMatch}
                                icon={<SpeedDialIcon icon={<MoreVertIcon />} />}
                                direction="left"
                                key={lend.id}
                                open={openSpeedDialMatch === lend.id}
                                onOpen={() =>
                                  this.handleChangeSpeedDialMatch(lend.id)
                                }
                                onClose={() =>
                                  this.handleChangeSpeedDialMatch(null)
                                }
                              >
                                <SpeedDialAction
                                  key="Bearbeiten"
                                  icon={<EditIcon />}
                                  tooltipTitle="Leihe bearbeiten"
                                  component={Link}
                                  to={`/edit-lend/${lend.id}`}
                                />
                                {editable &&
                                  (privileges.owner === 1 ||
                                    privileges.lend_delete === 1) && (
                                    <SpeedDialAction
                                      key="Löschen"
                                      icon={<DeleteIcon color="error" />}
                                      tooltipTitle="Leihe löschen"
                                      onClick={() =>
                                        this.handleDeleteLend(lend.id)
                                      }
                                    />
                                  )}
                              </SpeedDial>
                            </TableCell>
                          )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : privileges.lend_view === 1 || privileges.owner === 1 ? (
              <Typography gutterBottom>
                Für dieses Spiel wurde noch keine Leihe erfasst.
              </Typography>
            ) : (
              <Typography gutterBottom>
                Du hast keine Berechtigung, um die Leihen dieser Sammlung zu
                sehen.
              </Typography>
            )}
          </TabPanel>
          <TabPanel value={this.state.tabIndex} index={3}>
            {privileges.match_view === 1 || privileges.owner === 1 ? (
              <>
                <TableContainer component={Paper}>
                  <Table
                    className={classes.tableAltTable}
                    aria-label="statistic-details"
                  >
                    <TableBody>
                      <TableRow>
                        <TableCell>Anzahl Partien</TableCell>
                        <TableCell align="right">
                          {game.statistic ? game.statistic.cnt_matches : "0"}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Meist gespielt am</TableCell>
                        <TableCell align="right">
                          {game.statistic?.most_played_date?.date !==
                            undefined &&
                          moment(
                            game.statistic?.most_played_date?.date
                          ).isValid()
                            ? `${moment(
                                game.statistic?.most_played_date?.date
                              ).format("DD.MM.YYYY")} (${
                                game.statistic?.most_played_date?.cnt_matches
                              })`
                            : "n/A"}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Spieler mit den meisten Partien</TableCell>
                        {game.statistic?.most_played_player?.name ? (
                          <TableCell align="right">
                            {game.statistic?.most_played_player?.name} (
                            {game.statistic?.most_played_player?.cnt_matches})
                          </TableCell>
                        ) : (
                          <TableCell align="right">n/A</TableCell>
                        )}
                      </TableRow>
                      <TableRow>
                        <TableCell>Spieler mit den meisten Siegen</TableCell>
                        {game.statistic?.most_wins_player?.name ? (
                          <TableCell align="right">
                            {game.statistic?.most_wins_player?.name} (
                            {game.statistic?.most_wins_player?.cnt_wins})
                          </TableCell>
                        ) : (
                          <TableCell align="right">n/A</TableCell>
                        )}
                      </TableRow>
                      <TableRow>
                        <TableCell>Spieler mit der besten Siegesrate</TableCell>
                        {game.statistic?.best_winrate_player?.name ? (
                          <TableCell align="right">
                            {game.statistic?.best_winrate_player?.name} (
                            {game.statistic?.best_winrate_player?.winrate}%)
                          </TableCell>
                        ) : (
                          <TableCell align="right">n/A</TableCell>
                        )}
                      </TableRow>
                      <TableRow>
                        <TableCell>Meist gespielte Spieleranzahl</TableCell>
                        {game.statistic?.most_played_playercount?.cnt_player ? (
                          <TableCell align="right">
                            {
                              game.statistic?.most_played_playercount
                                ?.cnt_player
                            }{" "}
                            (
                            {
                              game.statistic?.most_played_playercount
                                ?.cnt_matches
                            }
                            )
                          </TableCell>
                        ) : (
                          <TableCell align="right">n/A</TableCell>
                        )}
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <Typography
                  className={classes.statisticHeader}
                  variant="h5"
                  align="center"
                  gutterBottom
                >
                  Je Spieler
                </Typography>
                <TableContainer component={Paper}>
                  <Table
                    className={classes.tableAltTable}
                    aria-label="statistic-details"
                  >
                    {game.statistic?.player_statistic ? (
                      <>
                        <TableHead>
                          <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align="right">Anz. Partien</TableCell>
                            <TableCell align="right">Anz. Siege</TableCell>
                            <TableCell align="right">Siegesrate</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {game.statistic?.player_statistic.map(
                            (player, index) => (
                              <TableRow>
                                <TableCell>{player.name}</TableCell>
                                <TableCell align="right">
                                  {player.cnt_matches}
                                </TableCell>
                                <TableCell align="right">
                                  {player.cnt_wins}
                                </TableCell>
                                <TableCell align="right">
                                  {player.winrate}%
                                </TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </>
                    ) : (
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            Diese Sammlung enthält noch keine Spieler.
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </>
            ) : (
              <Typography gutterBottom>
                Du hast keine Berechtigung, um die Spielstatistik dieser
                Sammlung zu sehen.
              </Typography>
            )}
          </TabPanel>
          <TabPanel value={this.state.tabIndex} index={4}>
            {game.leaderboard?.withExtensions.length > 0 ? (
              <>
                <FormControlLabel
                  control={
                    <Switch
                      checked={game.showLeaderboardWithExtensions}
                      onChange={this.handleChangeLeaderboard}
                      inputProps={{
                        "aria-label": "showLeaderboardWithExtensions",
                      }}
                    />
                  }
                  label="Partien mit Erweiterungen anzeigen"
                />
                {game.showLeaderboardWithExtensions ? (
                  <TableContainer component={Paper}>
                    <Table
                      className={classes.tableAltTable}
                      aria-label="match-history"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">Rang</TableCell>
                          <TableCell align="center">Punkte</TableCell>
                          <TableCell align="center">Spieler</TableCell>
                          <TableCell align="center">Datum</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {game.leaderboard.withExtensions.map((score, index) => (
                          <TableRow>
                            <TableCell align="center">{score.rank}</TableCell>
                            <TableCell align="center">{score.points}</TableCell>
                            <TableCell align="center">
                              {score.player_name}
                            </TableCell>
                            <TableCell align="center">
                              {moment(score.date).format("DD.MM.YYYY")}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : game.leaderboard.onlyMaingame.length > 0 ? (
                  <TableContainer component={Paper}>
                    <Table
                      className={classes.tableAltTable}
                      aria-label="match-history"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">Rang</TableCell>
                          <TableCell align="center">Punkte</TableCell>
                          <TableCell align="center">Spieler</TableCell>
                          <TableCell align="center">Datum</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {game.leaderboard.onlyMaingame.map((score, index) => (
                          <TableRow>
                            <TableCell align="center">{score.rank}</TableCell>
                            <TableCell align="center">{score.points}</TableCell>
                            <TableCell align="center">
                              {score.player_name}
                            </TableCell>
                            <TableCell align="center">
                              {moment(score.date).format("DD.MM.YYYY")}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Typography gutterBottom>
                    Für diese Selektion konnte keine Partie gefunden werden.
                  </Typography>
                )}
              </>
            ) : privileges.match_view === 1 || privileges.owner === 1 ? (
              game.recordPoints ? (
                <Typography gutterBottom>
                  Für dieses Spiel wurden noch keine Punkte erfasst.
                </Typography>
              ) : (
                <Typography gutterBottom>
                  Für dieses Spiel wurde das Erfassen von Punkten nicht
                  aktiviert. Solltest du für dieses Spiel Punkte erfassen
                  wollen, kannst du dies in den Spieldetails aktivieren.
                </Typography>
              )
            ) : (
              <Typography gutterBottom>
                Du hast keine Berechtigung, um das Leaderboard dieser Sammlung
                zu sehen.
              </Typography>
            )}
          </TabPanel>
        </SwipeableViews>
        {editable &&
          (privileges.owner === 1 ||
            privileges.game_modify === 1 ||
            privileges.match_modify === 1 ||
            privileges.lend_modify === 1) && (
            <>
              <SpeedDial
                ariaLabel="Spielmenü"
                className={classes.speedDial}
                icon={<SpeedDialIcon icon={<MoreVertIcon />} />}
                open={openSpeedDial}
                onOpen={this.handleChangeSpeedDial}
                onClose={this.handleChangeSpeedDial}
              >
                {editable &&
                  (privileges.owner === 1 || privileges.game_modify === 1) && (
                    <SpeedDialAction
                      key="Bearbeiten"
                      icon={<EditIcon />}
                      tooltipTitle="Spiel bearbeiten"
                      component={Link}
                      to={`/games/${game.id}/edit`}
                    />
                  )}
                {editable &&
                  (privileges.owner === 1 || privileges.match_insert === 1) && (
                    <SpeedDialAction
                      key="Spielen"
                      icon={<SportsEsportsIcon />}
                      tooltipTitle="Partie erfassen"
                      component={Link}
                      to={`/games/${game.id}/add-match`}
                    />
                  )}
                {editable &&
                  ((game.current_lend
                    ? privileges.lend_modify === 1
                    : privileges.lend_insert === 1) ||
                    privileges.owner === 1) && (
                    <SpeedDialAction
                      key="Verleihen"
                      icon={<ImportExportIcon />}
                      tooltipTitle={
                        game.current_lend ? "Zurückgeben" : "Verleihen"
                      }
                      component={Link}
                      to={
                        game.current_lend
                          ? `/edit-lend/${game.current_lend}`
                          : `/games/${game.id}/add-lend`
                      }
                    />
                  )}
              </SpeedDial>
            </>
          )}
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(GameDetails);
