import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import { useParams, Link } from "react-router-dom";

import {
  useAlertMessageStore,
  useHeaderStore,
  useLoadingStore,
} from "src/store/store";
import StatisticDataService from "src/services/statistic.service";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";

const StatisticRow = ({ label, value, link }) => (
  <TableRow>
    <TableCell>{label}</TableCell>
    <TableCell align="right">
      {link ? <Link to={link}>{value}</Link> : value}
    </TableCell>
  </TableRow>
);

const formatDate = (date) => {
  try {
    return format(new Date(date), "dd.MM.yyyy");
  } catch (e) {
    return "n/A";
  }
};

export default function PlayerStatistic() {
  const [player, setPlayer] = useState({ id: null, name: "" });
  const [statistic, setStatistic] = useState({});
  const { collection_id, player_id } = useParams();

  useEffect(() => {
    useHeaderStore.setState({
      title: "statistic.statistic_one",
      help: "help.statistic",
    });
    useLoadingStore.setState({ isLoading: true });

    const fetchStatistic = async () => {
      try {
        const response = await StatisticDataService.findByPlayer(
          collection_id,
          player_id
        );
        setPlayer(response.data.player);
        setStatistic(response.data.statistic);
      } catch (e) {
        console.error(e);
        useAlertMessageStore.setState({
          alertMessage: {
            severity: "error",
            message: `Fehler beim Laden der Statistik`,
          },
        });
      } finally {
        useLoadingStore.setState({ isLoading: false });
      }
    };

    fetchStatistic();
  }, [collection_id, player_id]);

  const formattedDateLatestBurrow = statistic.latest_burrow
    ? statistic.latest_burrow.date_end
      ? `(${formatDate(statistic.latest_burrow?.date_start)} - ${formatDate(
          statistic.latest_burrow?.date_end
        )})`
      : `(seit ${formatDate(statistic.latest_burrow?.date_start)})`
    : "";
  const formattedDateLatestMatch = statistic.latest_match
    ? `(${formatDate(statistic.latest_match.date)})`
    : "";
  return (
    <>
      <Typography variant="h5" align="center" gutterBottom>
        {player.name}
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-label="statistic-details">
          <TableBody>
            <StatisticRow
              label="Anzahl Partien"
              value={statistic.cnt_matches || "n/A"}
            />
            <StatisticRow
              label="Anzahl Siege"
              value={statistic.cnt_wins || "n/A"}
            />
            <StatisticRow
              label="Siegesrate"
              value={`${statistic.winrate?.toLocaleString("de-de") || "n/A"}%`}
            />
            <StatisticRow
              label="Spiel mit den meisten Partien"
              value={
                statistic.most_played_game
                  ? `${statistic.most_played_game?.game.name || "n/A"} (${
                      statistic.most_played_game?.cnt_matches || "n/A"
                    })`
                  : "n/A"
              }
              link={
                statistic.most_played_game &&
                `/games/${statistic.most_played_game.game.id}`
              }
            />
            <StatisticRow
              label="Spiel mit den meisten Siegen"
              value={
                statistic.most_won_game
                  ? `${statistic.most_won_game?.game.name || "n/A"} (${
                      statistic.most_won_game?.cnt_wins || "n/A"
                    })`
                  : "n/A"
              }
              link={
                statistic.most_won_game &&
                `/games/${statistic.most_won_game.game.id}`
              }
            />
            <StatisticRow
              label="Letzte Partie"
              value={`${
                statistic.latest_match?.game.name || "n/A"
              } ${formattedDateLatestMatch}`}
              link={
                statistic.latest_match &&
                `/games/${statistic.latest_match.game.id}`
              }
            />
            <StatisticRow
              label="Letzte Leihe"
              value={`${
                statistic.latest_burrow?.game.name || "n/A"
              } ${formattedDateLatestBurrow}`}
              link={
                statistic.latest_burrow &&
                `/games/${statistic.latest_burrow.game.id}`
              }
            />
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
