import React, { Component } from "react";
import withStyles from '@mui/styles/withStyles';
import { useHeaderStore } from "src/store/store";
import { Typography } from '@mui/material';

const useStyles = theme => ({

});
  
  class LegalNotice extends Component {
    constructor(props) {
      super(props);    
      this.props.setStateValue('isLoading', false)
      useHeaderStore.setState({ title: "Impressum", help: "help.legalNotice"})

    }

  componentDidMount() {
    this.props.setStateValue('isLoading', false)
  }
 

  render() {
    return (
    <React.Fragment>
    <Typography variant="h5" align="left" gutterBottom >Angaben gem&auml;&szlig; &sect; 5 TMG</Typography>
    <Typography variant="body2" align="left" gutterBottom >
                                            Steffen Oehler<br />
                                            Steffen Oehler IT-Dienstleistungen<br />
                                            Jägerhofstr. 109<br />
                                            42119 Wuppertal<br />
    </Typography>
    <Typography variant="h5" align="left" gutterBottom >Kontakt</Typography>
    <Typography variant="body2" align="left" gutterBottom >
                                            Telefon: +49 151 23233102<br />
                                            E-Mail: info@it-oehler.de
    </Typography>
    <Typography variant="h5" align="left" gutterBottom >EU-Streitschlichtung</Typography>
    <Typography variant="body2" align="left" gutterBottom >
                                            Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.
    </Typography>
    <Typography variant="h5" align="left" gutterBottom >Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</Typography>
    <Typography variant="body2" align="left" gutterBottom >
                                            Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
    </Typography>
    <Typography variant="h6" align="left" gutterBottom >Haftung f&uuml;r Inhalte</Typography>
    <Typography variant="body2" align="left" gutterBottom >
                                            Als Diensteanbieter sind wir gem&auml;&szlig; &sect; 7 Abs.1 TMG f&uuml;r eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach &sect;&sect; 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, &uuml;bermittelte oder gespeicherte fremde Informationen zu &uuml;berwachen oder nach Umst&auml;nden zu forschen, die auf eine rechtswidrige T&auml;tigkeit hinweisen.
    </Typography>
    <Typography variant="body2" align="left" gutterBottom >
                                            Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unber&uuml;hrt. Eine diesbez&uuml;gliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung m&ouml;glich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
    </Typography>
    <Typography variant="h6" align="left" gutterBottom >Haftung f&uuml;r Links</Typography>
    <Typography variant="body2" align="left" gutterBottom >
                                            Unser Angebot enth&auml;lt Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb k&ouml;nnen wir f&uuml;r diese fremden Inhalte auch keine Gew&auml;hr &uuml;bernehmen. F&uuml;r die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf m&ouml;gliche Rechtsverst&ouml;&szlig;e &uuml;berpr&uuml;ft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
    </Typography>
    <Typography variant="body2" align="left" gutterBottom >
                                            Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
    </Typography>
    <Typography variant="h6" align="left" gutterBottom >Urheberrecht</Typography>
    <Typography variant="body2" align="left" gutterBottom >
                                            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielf&auml;ltigung, Bearbeitung, Verbreitung und jede Art der Verwertung au&szlig;erhalb der Grenzen des Urheberrechtes bed&uuml;rfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur f&uuml;r den privaten, nicht kommerziellen Gebrauch gestattet.
    </Typography>
    <Typography variant="body2" align="left" gutterBottom >
                                            Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
    </Typography>
    <Typography variant="body2" align="left" gutterBottom >
                                            Quelle: <a href="https://www.e-recht24.de/impressum-generator.html" target="_blank" rel="noreferrer">https://www.e-recht24.de/impressum-generator.html</a>
    </Typography>
    </React.Fragment>
    )
  }
}

export default withStyles(useStyles)(LegalNotice)